<template>
  <div class="header-wrapper fluid-container">
    <div v-for="(route, i) in availableRoutes"
         :key="i"
         class="header-label">
      <router-link class="header-link"
                   :to="{ name: route.name, params: $route.params, query: $route.query }">
        {{ 'header_route_' + route.name | translate }}
      </router-link>
    </div>
  </div>
</template>

<script>
import routes from '@/router/routes';

export default {
  name: 'AppHeader',
  data: () => ({
    routes,
  }),
  computed: {
    availableRoutes() {
      return this.routes.filter((route) => this.$store.getters.userHasModule(route.meta.userModule));
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper {
  padding-top: 0.625em;
  padding-bottom: 0.625em;
  background: #fff;
  box-shadow: $shadow-outline;

  .header-label {
    display: inline-block;
    padding-right: 2rem;
  }

  .header-link {
    font-size: $fs-300;
    opacity: 0.8;
    color: $brand;
    cursor: pointer;

  }

  .router-link-active {
    font-weight: 600;
    opacity: 1;
  }
}
</style>
