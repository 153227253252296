<template>
  <div id="app-template">
    <app-header />
    <router-view />
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader';

export default {
  name: 'AppTemplate',
  components: {
    AppHeader,
  },
};
</script>
